
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-pagination{
        display: flex;
        justify-content: center;
        margin: 30px 0;
    }

    .nav{
        display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 54px;
            .el-breadcrumb {
                line-height: 40px;
            }
    }
    .tableClass{
        /deep/th{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
        /deep/td{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
    }
    .StoreMarket{
        /*overflow-x:hidden;*/
        /*overflow:scroll;*/
        height: calc(100vh - 320px);
        padding: 10px 30px 0 30px;
        .StoreMarketHeader{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .StoreMarketTable{

        }
    }
    /*.StoreMarket::-webkit-scrollbar {!*滚动条整体样式*!*/
    /*    width: 4px;     !*高宽分别对应横竖滚动条的尺寸*!*/
    /*    height: 4px;*/
    /*    scrollbar-arrow-color:red;*/
    /*    display: none;*/
    /*}*/
    /*.StoreMarket::-webkit-scrollbar-thumb {!*滚动条里面小方块*!*/
    /*    border-radius: 5px;*/
    /*    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
    /*    background: rgba(0,0,0,0.2);*/
    /*    scrollbar-arrow-color:red;*/
    /*}*/
    /*.StoreMarket::-webkit-scrollbar-track {!*滚动条里面轨道*!*/
    /*    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
    /*    border-radius: 0;*/
    /*    background: rgba(0,0,0,0.1);*/
    /*}*/

